
/*  add line in day parth/
/* .fc .fc-timegrid-slot.fc-timegrid-slot-minor {
  border-top: none;
} */
/* remove line from time part  */
.fc-timegrid-slot-label:first-child { 
  border-top: none;
 
}
.fc-timegrid-axis-frame {
  border-left: none;
}
.fc-toolbar button {
  font-size: 14px;
  margin-right: 5px;
}