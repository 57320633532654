
.no-border {
 /* this is for remove border-bottom from thead>tr>th for first th */
 border-bottom: none;
}
.table td {
 border-left: 1px solid #dee2e6;
}
.last-column {
 border-right: 1px solid #dee2e6;
}
.table tbody th {
 
 border-bottom: none;
 text-align: right !important;
 vertical-align: top !important;
 padding-top: 0;
 padding-left: 0;
}
.table tbody td {
 /*set height of td element in boddy( set height of time  */
 height: 60px;
}
.table th:not(:first-child) {
 /*set for every element execpt th tag to be same width */
 width: calc(100% / 7);
 box-sizing: border-box;

 border-left: none; 
 
}
.table th.day3 {
  width: calc(100% / 3);
}
.table th.day1 {
  width: calc(100% );
}
.boxForAvatar {
  /* background-color: red; */
  height: 120px;
  margin-top: 20px;
}

.staff-event-container {
  margin-bottom: 20px;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 419px) {
  .staff-event-container {
    width: 100%;
  }
}

@media (min-width: 420px) and (max-width: 732px) {
  .staff-event-container {
    width: 50%;
  }
}

@media (min-width: 733px) and (max-width: 800px) {
  .staff-event-container {
    width: 33.33%;
  }
}

@media (min-width: 801px) {
  .staff-event-container {
    width: 25%;
  }
}
/* .table thead  th {
border-right: none;

}
.table thead  tr {
  border-right: none;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  } */
  thead  {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 50px;
    border-top: 0.5px solid rgb(218, 205, 205);
    border-bottom: 0.5px solid rgb(218, 205, 205);
  }
.table td div {
 /*set for div in td tag in thbody to have border bottom and height( set height of time slot  */ 
 
 height: 50%;
 /* border-bottom: 1px solid #dee2e6; */
}


.table tbody td div:hover {
background-color: lightblue;
font-size: 1;
}/*  set to see content betweene div */




.time-cell:hover {
  background-color: rgb(55, 50, 49); /* or any other color you want on hover */
}

/* .table tbody td div:not(:hover) {
background-color: white;
font-size: 0; 
} hidde content betweene div */


.table {
  width: 100%;
}
tbody td {
  padding: 0 !important;
}
.cell-with-event {
  border-bottom: none;
}

@media (max-width: 800px) {
  table {
    width: 100%;
    table-layout: fixed;
  }
  
  td, th {
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  th:first-child {
    white-space: normal;
  }

  td:first-child {
    position: relative;
  }

  td:before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px;
    width: 50%;
    white-space: nowrap;
    font-weight: bold;
    font-size: 12px;
  }
}


.table-width {
  width: 100%;
}
/* set color of border for row in table */
tbody tr {
  border:  black;

}

/* media */

@media (max-width: 800px) {
  table {
    width: 100%;
    table-layout: fixed;
    
  }
  td, th {
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  th:first-child {
    white-space: normal;
    font-size: 8px;
    width: 40px;
    border: none;
  }
  th:not(:first-child) {
    font-size: 12px;
  }
  td:first-child {
    font-size: 12px;
    width: 50px;
  }
  td:not(:first-child) {
    font-size: 5px;
  }
 
  th:nth-child(2), td:nth-child(2) {
    font-size: 12px;
    width: 100px;
  }
/* set  width for first column time */
  table col:first-child {
    width: 7% !important;
  }
  @media (max-width: 450px) {
    table col:first-child {
      width: 12% !important;
    }
  }

}

@media (max-width: 800px) {
  th:first-child {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  th:first-child {
    font-size: 10px;
  }
}

@media (max-width: 400px) {
  th:first-child {
    font-size: 8px;
    width:30px;
  }
}


